<template>
  <div class="main-content bg-default">
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <span class="text-lead text-white">
                Welcome to
              </span>
              <br />
              <img :src="logo" />
              <br />
              <span class="text-lead text-white">
                Please Log-in to proceed
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary shadow border-0">
              <!-- <div class="card-body" style="padding: 2.5rem"> -->
              <div class="card-body px-lg-5 py-lg-5">
                <form role="form" @submit.prevent="getLinkedSitePages">
                  <br />
                  <div class="row mt--3 mb-2">
                    <div class="col">
                      <base-input label="Mobile Number/Email">
                        <a-input
                          placeholder="Enter mobile number or email"
                          v-model="formData.username"
                        />
                        <div
                          v-if="$v.formData.username.$dirty"
                          class="text-danger"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.username.required"
                          >
                            Field is required
                          </div>
                        </div>
                      </base-input>
                    </div>
                  </div>

                  <div class="row mt--3 mb-2">
                    <div class="col">
                      <base-input label="Password">
                        <a-input-password
                          placeholder="Password"
                          v-model="formData.password"
                        />
                        <div
                          v-if="$v.formData.password.$dirty"
                          class="text-danger"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.password.required"
                          >
                            Field is required
                          </div>
                        </div>
                      </base-input>
                    </div>
                  </div>
                  <div class="text-center">
                    <base-button
                      type="primary"
                      class="my-4"
                      nativeType="submit"
                      :disabled="formSubmitting"
                      style="width: 50%"
                    >
                      <a-spin
                        v-if="formSubmitting"
                        :spinning="formSubmitting"
                      />
                      <div v-else>Sign in</div>
                    </base-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </slide-y-up-transition>
    </div>
    <footer class="py-7">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
              <a
                href="https://www.iripple.com/"
                class="font-weight-bold ml-1"
                target="_blank"
                >Powered by iRipple, Inc.</a
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import secureStorage from '../../controllers/secureStorage';
import HttpRequest from '../../controllers/httpRequest';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'LinkLogin',
  components: {
    SlideYUpTransition
  },
  props: {
    logo: {
      type: String,
      default: '/img/brand/bxi-clp-blue-logo.png'
    }
  },
  data() {
    return {
      formSubmitting: false,
      formData: {
        username: null,
        password: null
      },
      siteName: null,
      loading: false,
      year: new Date().getFullYear()
    };
  },

  validations: {
    formData: {
      username: { required },
      password: { required }
    }
  },

  methods: {
    getLinkedSitePages: async function() {
      this.$v.formData.$touch();

      if (!this.$v.formData.$invalid) {
        this.loading = true;
        const { username, password } = this.formData;
        const response = await HttpRequest.postRequest(
          '/api/v2/clp_member_accounts/linked_sites',
          {
            account: {
              username,
              password
            }
          }
        );

        if (response.member !== undefined) {
          secureStorage.setItem('clpMemberData', response.member);

          this.$notification.success({
            message: 'Success'
          });

          setTimeout(() => {
            this.$router.push(`/${this.$route.params.siteName}/rewards`);
          }, 1000);
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.loading = false;
      }
    }
  }
};
</script>
<style scoped>
.square-img {
  max-height: 80px;
  max-width: 80px;
  height: auto;
  width: auto;
}
</style>
