<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <div class="col">
            <a-steps :current="currentStep">
              <a-step />
              <a-step />
            </a-steps>

            <br />
            <div v-if="currentStep == 0">
              <div class="text-center mb-3">
                <h3>Let's verify your account</h3>
                <small
                  >If you've signed up in store, enter your
                  <b>Mobile Number</b> below. </small
                >
              </div>
              <base-input label=" Mobile Number" required>
                <a-input
                  v-model="formData.user_account"
                  placeholder="Enter Mobile Number"
                />
                <div v-if="$v.formData.user_account.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.user_account.required">
                    Field is required
                  </div>
                </div>
                <small class="text-muted"
                  >We will only use this for verification</small
                >
              </base-input>
            </div>

            <div v-if="currentStep == 1">
              <div class="text-center mb-3">
                <h3>Enter One-Time Password</h3>
                <small
                  >The password was sent to: <b>{{ formData.email_mobile }}</b
                  >. The code will only be valid for 5 minutes.</small
                >
              </div>
              <base-input label=" One-Time Password" required>
                <a-input-search
                  placeholder="Enter OTP Code"
                  v-model="formData.code"
                  @search="resendOtp"
                >
                  <a-button
                    slot="enterButton"
                    :disabled="!canResendOTP"
                    v-if="resendTimer > 0"
                  >
                    Resend in {{ resendTimer }}(s)
                  </a-button>
                  <a-button
                    slot="enterButton"
                    :disabled="!canResendOTP || resendingOtp"
                    v-else
                  >
                    Resend OTP Request
                  </a-button>
                </a-input-search>
                <div v-if="$v.formData.code.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.code.required">
                    Field is required
                  </div>
                </div>
              </base-input>
            </div>
            <br />
            <div class="text-center">
              <!--<router-link
                :to="go(-1)"
                tag="button"
                class="btn btn-secondary"
                v-if="currentStep == 0"
              >
                Back
              </router-link> -->

              <b-button
                v-if="currentStep == 0"
                class="btn btn-secondary"
                @click="$router.back()"
              >
                Back
              </b-button>

              <b-button v-else @click="currentStep--">
                Back
              </b-button>

              <b-button
                v-if="currentStep == 0"
                variant="primary"
                @click="otpAccountVerification"
                :disabled="nextLoading"
              >
                <span v-if="!nextLoading">Next</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>

              <b-button
                v-else-if="currentStep == 1"
                variant="primary"
                @click="verifyOtp"
                :disabled="nextLoading"
              >
                <span v-if="!nextLoading">Next</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HttpRequest from '../../controllers/httpRequest';
import secureStorage from '../../controllers/secureStorage';
import { required } from 'vuelidate/lib/validators';
import HTTPRequest from '../../controllers/httpRequest';

export default {
  name: 'AccountVerification',
  props: {
    clpSitePageSettings: Object
  },
  data() {
    return {
      currentStep: 0,
      siteName: null,
      execFunc: true,
      formData: {
        user_account: null,
        member_id: null,
        code: null,
        source: null,
        password: null,
        email_mobile: null
      },
      email: null,
      mobileNo: null,
      canResendOTP: false,
      resendingOtp: false,
      resendTimer: 60, // in seconds
      timer: null,
      loggingIn: false,
      hasOtpGenerated: false,
      verifiedOtp: false,
      memberData: null,
      nextLoading: false,
      isEmail: false
    };
  },

  validations: {
    formData: {
      user_account: { required },
      code: { required },
      password: { required }
    }
  },
  methods: {
    otpAccountVerification: async function() {
      this.$v.formData.user_account.$touch();

      if (!this.hasOtpGenerated && !this.$v.formData.user_account.$invalid) {
        this.nextLoading = true;
        const { user_account,member_id } = this.formData;
        let membership_program_id = secureStorage.getItem('membershipProgramId');
        if(!membership_program_id) {
          const siteName = this.clpSitePageSettings.siteName
          const sitePage = await HTTPRequest.getRequest(
              '/api/v2/clp_site_pages/' + siteName + '/settings'
          );
          membership_program_id = sitePage.membership_program_id;
        }
        const response = await HttpRequest.postRequest(
          '/api/v2/clp_members/mobile/send-otp',
          {
            member_id,
            membership_program_id,
            mobile_number:user_account
          }
        );

        if (response.member_id !== undefined) {
          this.formData.source = response.source;
          this.mobileNo = response.mobile_no;
          this.hasOtpGenerated = true;
          this.isEmail = false;

          this.currentStep++;
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.nextLoading = false;
      } else if (
        this.hasOtpGenerated &&
        !this.$v.formData.user_account.$invalid
      ) {
        this.currentStep++;
      }
    },

    verifyOtp: async function() {
      this.$v.formData.code.$touch();

      if (!this.verifiedOtp && !this.$v.formData.code.$invalid) {
        this.nextLoading = true;
        const { member_id, user_account, code, source } = this.formData;

        const response = await HttpRequest.postRequest(
          '/api/v2/clp_members/mobile/verify_otp',
          {
            member_id, mobile_number:user_account, code, source
          }
        );

        if (response.message == 'Success') {
          this.$notification.success({
            message: 'OTP has been verified'
          });
          this.verifiedOtp = true;

          secureStorage.setItem('new_member_id', null);
          // Check if site page is allowed to update profile,
          // if allowed, redirect to profile updating module
          // else, redirect to dashboard module
          if (this.clpSitePageSettings.allow_self_update) {
            setTimeout(() => {
              this.$router.push({
                name: 'YourProfile',
                params: { siteName: this.clpSitePageSettings.siteName }
              });
            }, 1000);
          } else {
            setTimeout(() => {
              this.$router.push({
                name: 'Rewards',
                params: { siteName: this.clpSitePageSettings.siteName }
              });
            }, 1000);
          }
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.nextLoading = false;
      } else if (this.verifiedOtp && !this.$v.formData.code.$invalid) {
        secureStorage.setItem('new_member_id', null);
        if (this.clpSitePageSettings.allow_self_update) {
          setTimeout(() => {
            this.$router.push({
              name: 'YourProfile',
              params: { siteName: this.clpSitePageSettings.siteName }
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.$router.push({
              name: 'Rewards',
              params: { siteName: this.clpSitePageSettings.siteName }
            });
          }, 1000);
        }
      }
    },

    resendOtp: async function() {
      if (!this.verifiedOtp && this.canResendOTP) {
        this.resendingOtp = true;
        const { user_account,member_id } = this.formData;
        let membership_program_id = secureStorage.getItem('membershipProgramId');
        if(!membership_program_id) {
          const siteName = this.clpSitePageSettings.siteName
          const sitePage = await HTTPRequest.getRequest(
              '/api/v2/clp_site_pages/' + siteName + '/settings'
          );
          membership_program_id = sitePage.membership_program_id;
        }
        const response = await HttpRequest.postRequest(
            '/api/v2/clp_members/mobile/send-otp',
            {
              member_id,
              membership_program_id,
              mobile_number:user_account
            }
        );

        if (response.member_id !== undefined) {
          this.formData.source = response.source;
          this.formData.email_mobile = response.email_mobile;
          this.hasOtpGenerated = true;
          this.resendTimer = 60;
          this.canResendOTP = false;
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.resendingOtp = false;
      }
    },


    startResendTimer: function() {
      this.timer = setInterval(() => {
        this.resendTimer--;

        if (this.resendTimer <= 0) {
          this.resendTimer = 0;
          this.canResendOTP = true;
        }
      }, 1000);
    }
  },
  created() {
    this.formData.member_id = secureStorage.getItem('new_member_id')
  },
  watch: {
    currentStep(currStep) {
      if (currStep == 1) {
        this.startResendTimer();
      } else {
        this.resendTimer = 60;
        this.canResendOTP = false;
        clearInterval(this.timer);
      }
    }
  }
};
</script>
