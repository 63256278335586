import axios from 'axios';

const PSGCApi = {
  NCR_REGION_CODE: 130000000,
  async get(endpoint) {
    let responseData;
    await axios({
      method: 'get',
      url: 'https://psgc.gitlab.io/api' + endpoint
    }).then(response => {
      responseData = response.data;
    });

    return responseData;
  },

  async getRegions() {
    const res = await this.get('/regions');
    return res;
  },

  async getProvinces(regionCode) {
    let res = [];

    if (regionCode != null) {
      if (regionCode == this.NCR_REGION_CODE) {
        res = await this.get('/regions/' + regionCode + '/cities');
      } else {
        res = await this.get('/regions/' + regionCode + '/provinces');
      }
    }

    return res;
  },

  async getCitiesOrMunicipalities(provinceCode) {
    let res = [];

    if (provinceCode != null) {
      res = await this.get(
        '/provinces/' + provinceCode + '/cities-municipalities'
      );
    }

    return res;
  },

  async getCities(provinceCode) {
    let res = [];

    if (provinceCode != null) {
      res = await this.get('/provinces/' + provinceCode + '/cities');
    }

    return res;
  },

  async getBarangays(cityOrMunicipalityCode) {
    let res = [];

    if (cityOrMunicipalityCode != null) {
      res = await this.get(
        '/cities-municipalities/' + cityOrMunicipalityCode + '/barangays'
      );
    }

    return res;
  }
};

export default PSGCApi;
